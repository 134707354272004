
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import LiveTripNewEtaEvent from '@/components/LiveTripNewEtaEvent.vue'
import LiveTripArrivedEvent from '@/components/LiveTripArrivedEvent.vue'
import { EtaJourneyEvent, ArrivalJourneyEvent, LiveTripEvent, LiveTripEventCategory, LiveTripEventType } from '@/models/dto/LiveTripEvent'
import { createLiveTripEvent } from '@/services/tripMonitoring'
@Component({
  components: { LiveTripNewEtaEvent, LiveTripArrivedEvent },
})
export default class LiveTripAddEventSidebar extends Vue {
  @Prop({ required: true }) journeys!: any[]

  @Watch('eventSubType')
  onEventTypeChange(eventSubType) {
    if (eventSubType === LiveTripEventType.NEW_ETA) {
      this.event = new EtaJourneyEvent()
    } else if (eventSubType === LiveTripEventType.ARRIVAL) {
      this.event = new ArrivalJourneyEvent()
    }
  }

  eventTypes = [
    { text: 'Driver', value: LiveTripEventCategory.DRIVER },
    { text: 'Schedule', value: LiveTripEventCategory.ON_TIME },
    { text: 'Vehicle', value: LiveTripEventCategory.VEHICLE },
    { text: 'Tracking', value: LiveTripEventCategory.TRACKING },
  ]
  scheduleEventTypes = [
    { text: 'New ETA', value: LiveTripEventType.NEW_ETA },
    { text: 'Arrived', value: LiveTripEventType.ARRIVAL }
  ]

  event = new LiveTripEvent()
  eventType = null
  eventSubType = null
  loading = false

  get isScheduleEvent(): boolean {
    return this.eventType === LiveTripEventCategory.ON_TIME
  }

  get isNewETAEvent(): boolean {
    return this.eventSubType === LiveTripEventType.NEW_ETA
  }

  get isArrivedEvent(): boolean {
    return this.eventSubType === LiveTripEventType.ARRIVAL
  }

  get journeyId(): string {
    if (!this.journeys || this.journeys.length === 0) {
      return null
    }
    return this.journeys[0].externalJourneyId
  }

  close() {
    this.$store.dispatch('app/closeDialog')
  }

  handleUpdateEvent(event: any) {
    this.event = event
  }

  async submit() {
    const eventForm = this.$refs['event-form'] as any
    const validationResult = await eventForm.validate()
    if (!validationResult) {
      return
    }

    try {
      const payload = { journeyEvent: this.event }
      const res = await createLiveTripEvent(this.journeyId, payload)
      if (!res.data?.journeyEvent) {
        throw new Error('Failed to create event')
      }

      this.$store.dispatch('app/showAlert', {
        message: 'Event created successfully',
        type: 'success',
      })
      this.close()
    } catch(e) {
      console.error(e)
      this.$store.dispatch('app/showAlert', {
        message: 'Failed to create event',
        type: 'error',
      })
    } finally {
      this.loading = false
    }
  }

}
