var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form"},[_c('div',[_c('h4',{staticClass:"font-weight-700 font-16 margin-b-1"},[_vm._v("New ETA")]),_c('v-layout',{attrs:{"row":""}},[_c('CRInput',{staticClass:"margin-r-2 flex-basis-one-half",attrs:{"value":_vm.newArriveTime,"label":"New Arrive Time","type":"time","rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'New Arrive Time Required',
            error: 'New Arrive Time Required',
          }),
        ]},on:{"change":_vm.handleUpdateNewArriveTime}}),_c('CRSelect',{attrs:{"value":_vm.newArriveTimeZone,"items":_vm.timeZones,"label":"Timezone","rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Timezone Required',
            error: 'Timezone Required',
          }),
        ]},on:{"input":_vm.handleUpdateNewArriveTimeZone}})],1),_c('CRSelect',{attrs:{"value":_vm.relevantStopId,"label":"Relevant Stop","items":_vm.stopItems,"rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Stop Required',
            error: 'Stop Required',
          }),
        ]},on:{"input":_vm.handleUpdateRelevantStopId}}),_c('v-switch',{attrs:{"label":"Set Reminder","value":_vm.shouldSetReminder},on:{"change":_vm.handleUpdateShouldSetReminder}}),(_vm.shouldSetReminder)?_c('v-layout',{attrs:{"row":""}},[_c('CRInput',{staticClass:"margin-r-2 flex-basis-one-half",attrs:{"value":_vm.reminderTime,"label":"Time to remind","type":"time","rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Reminder Time Required',
            error: 'Reminder Time Required',
          }),
        ]},on:{"change":_vm.handleUpdateReminderTime}}),_c('CRSelect',{attrs:{"value":_vm.reminderTimezone,"label":"Timezone","items":_vm.timeZones,"rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Timezone Required',
            error: 'Timezone Required',
          }),
        ]},on:{"input":_vm.handleUpdateReminderTimezone}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }