
import { Vue, Component, Prop } from 'vue-property-decorator'
import { timeZones } from '@/utils/timeZones'
import { DateTime } from 'luxon'
import { getDatetimeISOFromDateAndTimeStrings, convertIsoToTime } from '@/utils/time'
import { isRequired, isNotEmpty } from '@/utils/validators'
@Component({})
export default class LiveTripNewEtaEvent extends Vue {
  @Prop({ required: true }) readonly journeys!: any[]
  @Prop({ required: true }) readonly event!: any

  timeZones = timeZones
  isRequired = isRequired
  isNotEmpty = isNotEmpty

  // Today's date in 2025-01-01 format
  get today() {
    return DateTime.local().toFormat('yyyy-MM-dd')
  }

  get shouldSetReminder() {
    return this.event?.alerts?.length > 0
  }

  get newArriveTime() {
    if (!this.event?.eta) {
      return null
    }
    const tz = this.newArriveTimeZone || DateTime.local().zoneName
    return convertIsoToTime(this.event?.eta, tz)
  }

  get newArriveTimeZone() {
    return this.event?.timeZone
  }

  get relevantStopId() {
    return this.event?.waypointId
  }

  get reminderTime() {
    if (!this.event?.alerts?.[0]?.snoozedUntil) {
      return null
    }

    const tz = this.reminderTimeZone || DateTime.local().zoneName
    return convertIsoToTime(this.event.alerts[0].snoozedUntil, tz)
  }

  get reminderTimeZone() {
    return this.event.alerts?.[0]?.timeZone
  }

  get activeJourney() {
    return this.journeys[0]
  }

  get stopItems() {
    if (!this.activeJourney) {
      return []
    }
    return this.activeJourney.stops.map((stop: any) => {
      const addressTitle = stop.address.title || stop.address.addressName
      const stopNumber = stop.orderIndex + 1
      const text = `Stop ${stopNumber}: ${addressTitle}`

      return {
        text,
        value: stop.waypointId
  }
    })
  }

  async validate() {
    const form = this.$refs.form as any
    const validationResult = await form.validate()
    return validationResult
  }


  // When updating the timezone, convert the existing arrive time into the new timezone
  handleUpdateNewArriveTimeZone(timeZone) {
    const eta = getDatetimeISOFromDateAndTimeStrings(this.today, this.newArriveTime, timeZone)
    this.$emit('update:event', {
      ...this.event,
      eta,
      timeZone
    })
  }

  // When updating the stop, update both the ETA and the timezone
  // to match the stop's timezone
  handleUpdateRelevantStopId(waypointId) {
    const stop = this.activeJourney.stops.find((stop: any) => stop.waypointId === waypointId)
    const timeZone = stop.address.timeZone

    const eta = getDatetimeISOFromDateAndTimeStrings(this.today, this.newArriveTime, timeZone)

    this.$emit('update:event', {
      ...this.event,
      waypointId,
      timeZone,
      eta
    })
  }

  handleUpdateNewArriveTime(e) {
    const tz = this.newArriveTimeZone ||  DateTime.local().zoneName
    const eta = getDatetimeISOFromDateAndTimeStrings(this.today, e, tz)
    this.$emit('update:event', {
      ...this.event,
      eta,
    })
  }

  handleUpdateShouldSetReminder(e: boolean) {
    const currentTime = new Date().toISOString()

    if (e) {
      this.$emit('update:event', {
        ...this.event,
        alerts: [
        {
          type: 'SNOOZED',
          status: 'ACTIVE',
          triggeredAt: currentTime,
          snoozedAt: currentTime,
          snoozedUntil: null
        }]
      })
    } else {
      this.$emit('update:event', {
        ...this.event,
        alerts: []
      })
    }
  }

  handleUpdateReminderTime(e) {
    if (!this.event.alerts.length) {
      return
    }
    const tz = this.reminderTimeZone ||  DateTime.local().zoneName
    const snoozedUntil = getDatetimeISOFromDateAndTimeStrings(this.today, e, tz)
    this.$emit('update:event', {
      ...this.event,
      alerts: [
        {
          ...this.event.alerts[0],
          snoozedUntil,
        }
      ]
    })
  }

  handleUpdateReminderTimezone(tz) {
    if (!this.event.alerts.length) {
      return
    }

    const reminderTime = getDatetimeISOFromDateAndTimeStrings(this.today, this.reminderTime, tz)

    this.$emit('update:event', {
      ...this.event,
      alerts: [
        {
          ...this.event.alerts[0],
          timeZone: tz,
          snoozedUntil: reminderTime
        }
      ]
    })
  }
}
